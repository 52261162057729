import {React, useState, useEffect} from 'react';
import './TopNav.css';
import leafletImg from '../assets/images/views/leaflet.png';
import Dropdown from './Dropdown';
import '../styles/material-symbols-outlined.css';
import {API_BASE_URL} from '../constants/constants'

function TopNav( {map}) {
  return (
    <div className="top-nav">
      <MapView map={map}/>
    </div>
  );
}

function MapView({map}) {
  // get the list of basemaps and labels
  const [basemaps, setBasemaps] = useState(null);
  const [labels, setLabels] = useState(null);
  const [loading, setLoading] = useState(true); // state for indicating if it is still loading data in

  // function fetches for the basemap/labels
  useEffect(()=>{
    const fetchAncillary = async() => {
      try{
        // fetch basemaps/labels
        const response = await fetch(`${API_BASE_URL}/ancillary`);
        const ancillary = await response.json();
        
        setBasemaps(ancillary.basemap);
        setLabels(ancillary.labels);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching basemaps/labels', error);
        setLoading(false);
      }
    };

    fetchAncillary();
  }, []);

  return (
    <div className='top-nav-container'>
      <div className='top-left'>
        <button className="map-view">
          <span className='view-icon'>
            <img src={leafletImg} alt="Leaflet logo" />
            <div className='down-arrow'></div>
          </span>
        </button>

        <div className='dropdown-container'>
          <Dropdown map={map} type="Basemap" data={basemaps}/>
          <Dropdown map={map} type="Label" data={labels}/>
        </div>
        <div className='info-container'>
          <label className='curr-time'>2024-08-19 1620UTC</label>
          <label className='curr-tile'>x: y: z: </label>
        </div>

      </div>
      <div className='top-right'>
        <span className="material-symbols-outlined nav-icons">
          search
        </span>
      </div>
    </div>
  )
}

export default TopNav;